<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="companies"
          item-key="name"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="companiesPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Companies</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('company_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Company
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Name -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCompany.name"
                              :rules="nameRules"
                              label="Name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Email -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCompany.email"
                              :rules="emailRules"
                              label="Email"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Email <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Address -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCompany.address"
                              :rules="addressRules"
                              label="Address"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Address <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Phone 1 -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCompany.phone1"
                              :rules="phone1Rules"
                              label="First Phone number"
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  First Phone number
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Phone 2 -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCompany.phone2"
                              :rules="phone2Rules"
                              label="Second Phone number"
                            >
                              <template v-slot:label>
                                <p class="body-1">Second Phone number</p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Web Site -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCompany.website"
                              label="Web Site"
                            >
                              <template v-slot:label>
                                <p class="body-1">Web Site</p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Ative -->
                          <v-col class="mt-n5" cols="12">
                            <v-checkbox
                              v-model="editedCompany.active"
                              :label="`Active`"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this company?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="btn btn-primary white--text"
                      @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="btn btn-primary white--text"
                      @click="deleteCompanyConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- active -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.active="{ item }">
            <v-chip
              v-if="item.active"
              class="ma-2"
              color="green"
              text-color="white"
            >
              Yes
            </v-chip>

            <v-chip v-else class="ma-2" color="red" text-color="white">
              No
            </v-chip>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('company_update')"
              small
              class="mr-2"
              @click="editCompany(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('company_delete')"
              small
              @click="deleteCompany(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Companies per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementCompanies",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "Name", value: "name" },
        // { text: "Address", value: "address" },
        { text: "Email", value: "email" },
        { text: "First Phone Number", value: "phone1" },
        { text: "Second Phone Number", value: "phone2" },
        { text: "Web Site", value: "website" },
        { text: "Active", value: "active" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      companies: [],
      editedIndex: -1,
      editedCompany: {
        id: 0,
        name: "",
        address: "",
        email: "",
        phone1: "",
        phone2: "",
        website: "",
        active: true,
        created_at: "",
        updated_at: "",
      },
      defaultCompany: {
        id: 0,
        name: "",
        address: "",
        email: "",
        phone1: "",
        phone2: "",
        website: "",
        active: true,
        created_at: "",
        updated_at: "",
      },

      // name
      nameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // address
      addressRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // email
      emailRules: [
        (v) => !!v || "Required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // website
      websiteRules: [
        (v) =>
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(
            v
          ) || "Please enter valid website link",
        (v) => v.length <= 100 || "Website can not be more than 100 characters",
      ],

      // First Phone Number
      phone1Rules: [
        (v) => !!v || "Required",
        (v) => v.length <= 20 || "Can not be more than 20 characters",
        // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
        // (v) => v.length == 12 || "Mobile phone is incorrect",
      ],

      // Second Phone Number
      phone2Rules: [
        // (v) => !!v || "Required",
        // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
        // (v) => v.length == 12 || "Mobile phone is incorrect",
      ],

      page: 1,
      companiesPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1 ? "New Company" : "Edit Company";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedCompanies();
    },

    // companiesPerPage
    companiesPerPage() {
      this.getApiPagedCompanies();
    },

    // perPageChoice
    perPageChoice(val) {
      this.companiesPerPage = val.value;
      this.page = 1;
    },

    // editedCompany.phone1
    // "editedCompany.phone1"(val) {
    //   if (val != null && val != "") {
    //     this.editedCompany.phone1 = this.$helpers.formatInputMobileNumber(val);
    //   }
    // },

    // editedCompany.phone2
    // "editedCompany.phone2"(val) {
    //   if (val != null && val != "") {
    //     this.editedCompany.phone2 = this.$helpers.formatInputMobileNumber(val);
    //   }
    // },
  },

  created() {
    if (!this.$can("company_access")) {
      this.$router.push("/dashboard/inbox");
    }

    // this.getApiCompanies();
    this.getApiPagedCompanies();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiCompany();
        } else {
          this.createApiCompany();
        }
      }
    },

    // editCompany
    editCompany(company) {
      this.editedIndex = this.companies.indexOf(company);
      this.editedCompany = Object.assign({}, company);
      this.dialog = true;

      if (this.editedIndex !== -1) {
        this.$refs.form.validate();
      }
    },

    // deleteCompany
    deleteCompany(company) {
      this.editedIndex = this.companies.indexOf(company);
      this.editedCompany = Object.assign({}, company);
      this.dialogDelete = true;
    },

    // deleteCompanyConfirm
    deleteCompanyConfirm() {
      this.deleteApiCompany();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedCompany = Object.assign({}, this.defaultCompany);
        this.editedIndex = -1;
      });

      this.$refs.form.reset();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedCompany = Object.assign({}, this.defaultCompany);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // Get Api Companies
    async getApiCompanies() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("companies");

      if (res.status == 200) {
        this.companies = res.data;
      }

      this.overlay = false;
    },

    // Get Api Paged Companies
    async getApiPagedCompanies() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `companies/paged?page_no=${this.page}&page_size=${this.companiesPerPage}`
      );

      if (res.status == 200) {
        this.companies = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // Create Api Company
    async createApiCompany() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        "companies",
        {
          name: this.editedCompany.name,
          address: this.editedCompany.address,
          email: this.editedCompany.email,
          phone1: this.editedCompany.phone1,
          phone2: this.editedCompany.phone2,
          website: this.editedCompany.website,
          active: this.editedCompany.active,
        },
        "Company Created Successfully!"
      );

      if (res.status == 200) {
        this.companies.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Update Api Company
    async updateApiCompany() {
      this.overlay = true;

      const res = await this.$helpers.updateApiData(
        `companies/${this.editedCompany.id}`,
        {
          id: this.editedCompany.id,
          name: this.editedCompany.name,
          address: this.editedCompany.address,
          email: this.editedCompany.email,
          phone1: this.editedCompany.phone1,
          phone2: this.editedCompany.phone2,
          website: this.editedCompany.website,
          active: this.editedCompany.active,
        },
        "Company Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.companies[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Delete Api Company
    async deleteApiCompany() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `companies/${this.editedCompany.id}`,
        "Company Deleted Successfully!"
      );

      if (res.status == 200) {
        this.companies.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
