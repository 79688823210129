<template>
  <div class="background">
    <Companies />
  </div>
</template>

<script>
import Companies from "@/components/elements/dashboard/companies/Companies";

export default {
  name: "DocumentManagementCompanies",

  components: {
    Companies,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
